"use client"

import {useState} from 'react'
import {IndexPrice} from "@/app/(client)/(index)/components/pricing/index-price";


export const features = [
    "Lifetime Access",
    "Unlimited File Uploading",
    "Unlimited Buckets",
    "Bring-Your-Own-Bucket (S3 & R2)",
//    "React Components",
    "Signed Safety URLs",
    "Rest API",
    
]
export default function IndexPricing() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div id={"pricing"} className={"my-20 mb-20 gap-y-32 grid text-center"}>
            <div>
                <div>
                    <p className={"hidden md:block md:text-5xl font-mono font-bold"}>Complete Access. One-time Payment.</p>
                    <p className={"block md:hidden text-3xl font-mono font-bold"}>Complete Access.<br/>One-time Payment.</p>
                    <p className={"text-xl text-center md:text-xl mt-2 font-mono font-medium"}>Pay once to unlock complete access, then pay for your usage. That's it.</p>
                </div>
                <div className={"md:flex gap-y-4 md:gap-y-0 md:gap-x-8 mt-12"}>
                    <IndexPrice border={true}/>
                    <div className={"mt-8 text-left w-full gap-y-5 grid opacity-80"}>
                        <div>
                            <p className={"text-xl md:text-3xl font-mono font-bold"}>Usage</p>
                            <ul className={"text-xl md:text-xl mt-2 font-mono font-medium"}>
                                <li key={"1"}>2 cents per GB per month</li>
                                <li key={"2"}>50 cents per million reads</li>
                                <li key={"3"}>5 dollars per million writes*</li>
                            </ul>
                        </div>
                        <div>
                            <p className={"text-lg md:text-3xl font-mono font-bold"}>...</p>
                        </div>
                        <div>
                            <p className={"text-xl md:text-3xl font-mono font-bold"}>Bring Your Own Bucket</p>
                            <ul className={"text-xl md:text-xl mt-2 font-mono font-medium"}>
                                <li key={"1"}>zero usage fees*</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p className="text-left mr-auto mt-4 opacity-50 text-xs">
                    * You may be charged for usage from the platform of your bucket.
                    </p>
                <p className="text-left mr-auto mt-0 opacity-50 text-xs">
                * Writes also include expensive operations like listing files.
                </p>
            </div>
        </div>
        )
}
