import { runCheckout } from "@/lib/stripe-utils";
import {features} from "@/app/(client)/(index)/components/index-pricing";
import { CSSProperties, useEffect, useRef } from "react";

interface Props {
    border: boolean
}
export function IndexPrice({border}: Props) {
    const boxRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const boxElement = boxRef.current;

        if (!boxElement) {
            return;
        }

        const updateAnimation = () => {
            const angle =
                (parseFloat(boxElement.style.getPropertyValue("--angle")) + 0.3) % 360;
            boxElement.style.setProperty("--angle", `${angle}deg`);
            requestAnimationFrame(updateAnimation);
        };

        requestAnimationFrame(updateAnimation);
    }, []);



    return (
        <>
        <div
            ref={boxRef}
//            style={
//                        {
//                            "--angle": "0deg",
//                            "--border-color": "linear-gradient(var(--angle), #fff, #34d399)",
//                            "--bg-color": "linear-gradient(#fff, #fff)",
//                        } as CSSProperties
//                    }
            className={`${border && "shadow-lg border-2 border-[#0000] [background:padding-box_var(--bg-color),border-box_var(--border-color)]"} w-full max-w-lg rounded-xl p-4 mt-6`}>
                        <div className={"flex text-left justify-between"}>
                            <div>
                                <p className={"text-xl font-bold"}>One-time Payment</p>
                                <p className={"text-md"}>Complete access, all features.</p>
                            </div>
                            <div className={"my-auto"}>
                                <p className={"text-xs font-semibold bg-accent rounded-md px-2"}>LIFETIME</p>
                            </div>
                        </div>
                        <div className={"flex mt-4 p-4 border-[1.5px] rounded-lg"}>
                            <div className={"w-min mx-auto truncate flex gap-x-2"}>
                                <div className={"flex gap-x-2"}>
                                    <a className={"opacity-60 text-xl line-through flex font-bold items-center"}>
                                        <svg className={"h-full w-2 fill-black dark:fill-white"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M160 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.1-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7 0 0C119.4 279.3 84.4 270 58.4 253c-14.2-9.3-27.5-22-35.8-39.6c-8.4-17.9-10.1-37.9-6.1-59.2C23.7 116 52.3 91.2 84.8 78.3c13.3-5.3 27.9-8.9 43.2-11V32c0-17.7 14.3-32 32-32z"/></svg>
                                        129
                                    </a>
                                    <a className={"text-5xl flex font-bold items-center"}>
                                        <svg className={"h-full w-5 fill-black dark:fill-white"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M160 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.1-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7 0 0C119.4 279.3 84.4 270 58.4 253c-14.2-9.3-27.5-22-35.8-39.6c-8.4-17.9-10.1-37.9-6.1-59.2C23.7 116 52.3 91.2 84.8 78.3c13.3-5.3 27.9-8.9 43.2-11V32c0-17.7 14.3-32 32-32z"/></svg>
                                        89
                                    </a>
                                </div>
                                <div className={"my-auto text-left"}>
                                    <p className={"text-sm font-light"}>one-time payment</p>
                                    <p className={"text-sm font-light opacity-50"}>then pay for usage</p>
                                </div>
                            </div>
                        </div>
                        <div className={"my-4 mx-auto text-center ml-10"}>
                            <ul>
                                {features.map((season,index) => (
                                    <li key={index} className={"flex gap-x-2"}>
                                        <svg className={"w-5 h-5 fill-emerald-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                        <p className={"font-normal text-left"}>{season}</p>
                                    </li>
                                    ))}

                            </ul>
                        </div>
                        <button data-umami-event="IndexPricingComponent: Sign_Up_BUTTON" onClick={(e)=>runCheckout(undefined)} className={`w-full hover:opacity-60 mt-10 transition-all hover:animate-pulse font-normal text-md bg-emerald-300 text-black transition-all p-1 py-2 px-3 rounded-lg `}>
                            Sign Up ―
                            <a className={"font-light italic ml-1"}>start uploading!</a>
                        </button>
                    </div>
        </>
    )
}